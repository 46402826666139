.app_content {
	min-height: calc(100vh - 200px);
}

body {
	background-image: url("../images/background-pattern.jpg");
	background-repeat: repeat;
}

#profile {
	border-radius: 50%;
}

body {
	background-color: #ffffff;
}

.App {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: auto;
	width: 100%;
}

footer a {
	color: inherit;
	outline: none;
}

.card, .card-img, .card-img-top {
	border-top-left-radius: calc(0rem);
	border-top-right-radius: calc(0rem);
	border-radius: .25rem;
}

.card-img-top {
	border-bottom-left-radius: 0rem;
	border-bottom-right-radius: 0rem;
}

.rounded {
	border-radius: 0rem !important;
}

.card .bg-image {
	border-top-left-radius: 0rem;
	border-top-right-radius: 0rem;
}

.card-body {
	padding: 1rem 1rem;
}

.btn {
	border-radius: .15rem;
}

#profile, #logo {
	transition: all ease 500ms;
}

#profile:hover, #logo:hover {
	transform: scale(1.10);
	opacity: 0.7;
}

button#rcc-decline-button, button#rcc-confirm-button {
	margin: unset !important;
	margin-right: 15px !important;
}

button#rcc-decline-button {
	margin-left: 15px !important;
}

button#rcc-confirm-button {
	background: rgba(76, 161, 86) !important;
	color: rgb(229, 229, 229) !important;
}

#slider-dark .slick-prev:before, #slider-dark .slick-next:before {
	color: #262626 !important;
}

#slider-light .slick-dots button:before {
	color: #fefefe !important;
}

.testimonials .slick-slide img {
	display: inline;
}

.testimonials .slick-prev {
	left: 2%;
}

.testimonials .slick-next {
	right: 3%;
}

.mixtape .slick-prev {
	left: -20%;
}

.mixtape .slick-next {
	right: -18%;
}

.slick-prev {
	z-index: 1;
}

.slick-prev:before, .slick-next:before {
	font-size: 30px;
}
